<template>
  <div style="position:relative; height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row>
        <v-col lg="8" offset-lg="2">
          <v-card class="pa-6 mt-7">
            <h3 class=mb-7>{{ $t('notice.create.title') }}</h3>
            <v-select
                v-if="active_customers"
                :items="customers"
                :label="$t('notice.create.customers')"
                item-text="full_name"
                item-value="id"
                v-model="form.customer_id"
                outlined
                :disabled="customers_disabled"
            ></v-select>
            <v-textarea
                outlined
                :label="$t('notice.create.textarea')"
                v-model="form.text"
                :error="errors.text!==undefined"
            ></v-textarea>
            <v-row>
              <v-col v-if="!customers_disabled" sm="12" md="4" lg="6">
               <div class="d-flex">
                 <div class="d-flex align-center mr-12">
                   <v-switch
                       class="mr-2"
                       v-model="form.status"
                   ></v-switch>
                   <span style="color: rgba(0, 0, 0, 0.6)">{{ $t('notice.status.title') }}</span>
                 </div>
                 <div class="d-flex align-center">
                   <v-switch
                       class="mr-2"
                       v-model="active_customers"
                   ></v-switch>
                   <span style="color: rgba(0, 0, 0, 0.6)">{{ $t('notice.notice_about_a_customer') }}</span>
                 </div>
               </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" class="pr-0 ml-auto">
                <v-btn
                    style="width: 100%; padding: 28px;"
                    elevation="0"
                    color="green"
                    @click="save"
                    :loading="btn_create_loading"
                    :disabled="btn_create_loading"
                >
                  {{ $t('notice.btn.save') }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-btn
                    style="width: 100%; padding: 28px;"
                    elevation="0"
                    @click="backTo"
                >
                  {{ $t('notice.btn.abort') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active_customers: false,
      customers_disabled: false,
      preloader: true,
      btn_create_loading: false,
      customers: [],
      errors: [],
      form: {
        customer_id: null,
        text: '',
        status: true
      }
    }
  },
  watch: {
    'form.text'() {
      this.errors = []
    },
    'form.customer_id'() {
      if (this.form.customer_id !== null) {
        this.active_customers = true
      }
    }
  },
  created() {
    if (this.$route.params.customer_id !== null) {
      this.customers_disabled = true
    }
    this.form.customer_id = this.$route.params.customer_id
    this.getCustomers()
  },
  methods: {
    async getCustomers() {
      let customers = this.$store.getters.companies.data
      if (undefined === customers) {
        await this.$store.dispatch('companies', 1).then(() => {
          customers = this.$store.getters.companies.data
        })
      }

      this.customers = customers.map(customer => {
        customer.customer['full_name'] = customer.customer.first_name + ' ' + customer.customer.last_name
        return customer.customer
      })

      this.preloader = false
    },
    save() {
      this.btn_create_loading = true

      if (!this.active_customers) {
        this.form.customer_id = null
      }

      this.$store.dispatch('noticeCreate', this.form).then(() => {
        this.backTo()
      }).catch((error) => {
        this.btn_create_loading = false
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    backTo() {
      this.$router.go(-1)
    }
  }
}
</script>
